<template>
  <el-row>
    <el-col :span="20">
      <baidu-map :center="map.center" style="height: calc(100vh - 160px);" :scroll-wheel-zoom="true"
                 :zoom="map.zoom" :max-zoom="19" :min-zoom="2"
                 :mapStyle="mapStyle" @mousemove="mouseMove" @click="getClickInfo"
                 class="baidumap"  @ready="handlerReady">
        <bm-tile
            :isTransparentPng="true"

            tileUrlTemplate="http://www.yz-awen.cn:9090/files/tiles/{Z}/tile-{X}_{Y}.png" v-if="showTile">
          <!--        tileUrlTemplate="http://www.yz-awen.cn:9090/files/roadmap/{Z}/{X}/{Y}.jpg"v-if="isWaPian">-->


          <!--        "https://www.yz-exam.cn:8081/assets/tiles/{Z}/tile-{X}_{Y}.png"-->
        </bm-tile>
        <bm-control>
          <h2 style="padding: 20px; font-family: KaiTi_GB2312; font-size: 26px; ">宜州区国家气象观测站环境监控图</h2>
        </bm-control><!------拾取坐标，右键关闭------->
        <bm-marker v-if="test" :icon="{url: require('@/assets/imgs/bg.jpg'), size: {width: 0, height: 0}}" :key="100" :position="testPoint">
          <bm-label :content="testPoint.lng+ ', '+ testPoint.lat" :position="testPoint" :labelStyle="{color: 'red', fontSize : '12px'}" :offset="{width: 12, height: 24}"/>
        </bm-marker>
          <bm-marker v-if="test1" :icon="{url: require('@/assets/imgs/定点.png'), size: {width: 32, height: 32}}" :key="200" :position="testPoint">
          </bm-marker>
        <bm-circle :center="circlePath.center" :radius="circlePath.radius"
                   stroke-color="red" :stroke-opacity="0.2" :stroke-weight="1"
                   fillColor="orange" :fillOpacity="0.08"
                   :editing="false"></bm-circle>
        <template v-for="(it,index) in markers" > <!-- 显示所有标记点 --->
          <bm-label :content="it.mkname" :position="it.position" :offset="{width: -it.tl*6-3, height: -52}"
                    :labelStyle="{color: 'red', fontSize : '12px', border: '2px solid orange', borderRadius: '8px', backgroundColor: 'orange'}"
                    :title="it.mkname"/>
          <bm-marker :key="it.id" :position="it.position"
              :icon="{url: require(it.mktype==='0'?'@/assets/imgs/location0.png':'@/assets/imgs/location.png'), size: {width: 32, height: 64}}"
              @click="markclick(it,index)"
              @mouseover="markover(it,index)"
              @mouseout="markout(it,index)">    <!-- @open="infoWindowOpen" -->
            <bm-info-window @close="infoWindowClose" title="it.mkname" :show="it.show" :position="it.position" :offset="{width: 0, height: 0}">
              <div style="padding:5px;background: #2a60c9; font-size: 16px; font-weight: 600;">{{ it.mkname }}</div>
              <div v-if="it.mktype!=0" style="color:#000;font-size:14px;padding: 5px">
<!--                  <div style="margin-top:5px;width:220px">地址：{{ it.address }}</div>-->
                  <div style="margin-top:5px;">经纬度：{{ it.gps }}</div>
                  <div style="margin-top:5px;width:220px">海拔高度：{{ it.mkaltitude }}米</div>
                  <div style="margin-top:5px;width:220px">距站点距离：{{ it.mkdistance}}米</div>
                  <div style="margin-top:5px;width:220px">安全高度：{{ it.mksafety }}米</div>
                <div style="margin-top:5px;width:220px">建筑高度：{{ it.mkbuilding }}米</div>
                <div style="margin-top:5px;width:220px">更新时间：{{ it.mkdate }}</div>
              </div>
              <div v-else>
                <div style="background: #f38033;color: #000;padding: 10px;">
                  <span>台站全称：{{it.mkremarks}}</span>
                </div>
                <div style="color:#000;font-size:14px;padding: 5px;background: #e56c3c;">
                  <div style="margin-top:5px;">经纬度：{{ it.gps }}</div>
                  <div style="margin-top:5px;width:220px">海拔高度：{{ it.mkaltitude }}米</div>
                </div>
              </div>
            </bm-info-window>
          </bm-marker>
        </template>
      </baidu-map>
    </el-col>
    <!--    右侧按键区-->
    <el-col :span="4">
      <el-card style=" background: #7fa0df;height: calc(100vh - 160px);">
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-around;height: 400PX">
          <el-button size="medium"plain @click="backToCenter"icon="el-icon-aim">复    位</el-button>
          <el-button size="medium" type="primary"plain @click="openDistanceTool">开启测距</el-button>
          <el-button size="medium" type="success"plain  @click="getLngLat">{{ button3 }}</el-button>
          <el-popover
              placement="right"
              title="新增标记"
              trigger="manual" v-model="visible">
            <div style="display: flex; flex-direction: column; justify-content: space-around; align-items: center">
              <el-button type="text" @click="addMarker1"><img src="@/assets/imgs/mark.png" style="width: 12px;"></img>在地图上做标记点</el-button>
              <el-button  type="text" icon="el-icon-edit" @click="innerVisible=true;visible=!visible">输入坐标做标记点</el-button>
            </div>
            <el-button size="medium" type="info" plain slot="reference"@click="visible = !visible;test1=false">新增标记</el-button>
          </el-popover>
          <el-button size="medium" type="warning"plain @click="showViewer = true">方位照片</el-button>
          <el-button size="medium" type="warning"plain @click="outerVisible=true">数据列表</el-button>
          <el-button size="medium" type="danger"plain @click="showTile=!showTile">打开瓦片图</el-button>
          <el-button size="medium" type="warning"plain @click="distanceVisible = true">两点间距离</el-button>
        </div>
        <div >

          <el-image-viewer
              v-if="showViewer"
              :onSwitch="onSwitch"
              :on-close="closeViewer"
              :url-list="srcList"
          />

        </div>
      </el-card>
      <div class="fileName" v-if="showViewer">
        方位：{{position[photoDta[num].position]}}
        <el-button type="primary" plain @click="phototDownload">下载
<!--        <el-link :href="photoDta[num].imagePath">下载</el-link>-->
        </el-button>
      </div>
      <el-dialog :close-on-click-modal="false" :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="dialogVisible"
          width="30%" top="30vh" left="1vw"
          @open="dialogOpen">
        <div slot="title" style="font-size: 24px;">编辑标记点信息</div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="标记点名称" prop="mkname">
              <el-input v-model="ruleForm.mkname"></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="海拔高度(米)" prop="mkaltitude">
                  <el-input @input="handleAltitude" v-model="ruleForm.mkaltitude" @blur="calcHigh"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="建筑高度(米)" prop="mkbuilding">
                  <el-input @input="handleBuilding" v-model="ruleForm.mkbuilding"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="标记点说明" prop="mkcontent">
              <el-input v-model="ruleForm.mkcontent"></el-input>
            </el-form-item>
            <el-form-item label="备注信息" prop="mkremarks">
              <el-input v-model="ruleForm.mkremarks"></el-input>
            </el-form-item>
            <div style="color: #2a60c9;width: 100%; background: #eee;">
            <el-form-item label="GPS坐标：">
              {{ ruleForm.GPS }}
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="离中心距离：" prop="mkdistance">
                  {{ ruleForm.mkdistance }}米
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="建筑安全高度：" prop="mksafety">
                  <span>{{ ruleForm.mksafety ===0 ? '--': ruleForm.mksafety + '米' }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            </div>

          </el-form>
        <div slot="footer" class="dialog-footer">
<!--          <el-form-item>-->
            <el-button @click="dialogCancel">取 消</el-button>
            <!--              <el-button @click="resetForm('ruleForm')">重置</el-button>-->
            <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
<!--          </el-form-item>-->
        </div>
      </el-dialog>
<!--      直接输入坐标创建标记点-->
      <el-dialog :close-on-click-modal="false" :close-on-press-escape="false":show-close="false"
                 width="30%" title="请输入标记点GPS经纬度" :visible.sync="innerVisible"  append-to-body>
        <el-form :model="intputForm" ref="intputForm" label-width="60px" class="demo-ruleForm">
          <div style="text-align: center;padding: 10px;margin-bottom: 20px;">
            <el-radio-group v-model="radio" size="small" @change="changeRadio" fill="#66b1ff">
            <el-radio v-model="radio" label="1">数值模式</el-radio>
            <el-radio v-model="radio" label="2">度分秒模式</el-radio>
            </el-radio-group>
          </div>
            <div style="padding: 0 20px;">
              <el-form-item label="经度：" prop="mksafety">
                <div  v-if="radio==1">
                  <el-input @input="handleLng" v-model="intputForm.intputLng"></el-input>
                </div>
                <div v-else style="display: flex;">
                  <el-input @input="handleLng1" v-model="intputForm.intputLng1"></el-input><span style="font-size: 24px;padding-right: 10px">°</span>
                  <el-input @input="handleLng2" v-model="intputForm.intputLng2"></el-input><span style="font-size: 24px;padding-right: 10px">'</span>
                  <el-input @input="handleLng3" v-model="intputForm.intputLng3"></el-input><span style="font-size: 24px;padding-right: 10px">"</span>
                </div>
              </el-form-item>
              <el-form-item label="纬度：" prop="mksafety">
                <div  v-if="radio==1">
                  <el-input @input="handleLat" v-model="intputForm.intputLat"></el-input>
                </div>
                <div v-else style="display: flex;">
                  <el-input @input="handleLat1" v-model="intputForm.intputLat1"></el-input><span style="font-size: 24px;padding-right: 10px">°</span>
                  <el-input @input="handleLat2" v-model="intputForm.intputLat2"></el-input><span style="font-size: 24px;padding-right: 10px">'</span>
                  <el-input @input="handleLat3" v-model="intputForm.intputLat3"></el-input><span style="font-size: 24px;padding-right: 10px">"</span>
                </div>
              </el-form-item>
            </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="outerVisible = false;innerVisible=false">取 消</el-button>
          <el-button type="primary" @click="handlerIntputGPS">确 定</el-button>
        </div>
    </el-dialog>
      <el-dialog :close-on-click-modal="false" :close-on-press-escape="false"
                 top="5vh" width="80%" :visible.sync="outerVisible">
        <div slot="title" style="padding: 10px;text-align: center; font-size: 24px;">
          <strong><span style="color: #e56c3c">{{ circlePath.mkunit }}{{ circlePath.mkname }}</span>环境监控标记点信息表</strong>
        </div>
        <el-table :data="tableData" border stripe style="height: 480px; font-size: 14px;">
          <el-table-column property="mkname" label="标记名称" width="150"></el-table-column>
          <el-table-column property="mklng" label="GPS经度" width="100"></el-table-column>
          <el-table-column property="mklat" label="GPS纬度" width="100"></el-table-column>
          <el-table-column property="mkdistance" label="距离(米)" width="60"></el-table-column>
          <el-table-column property="mkaltitude" label="海拔高度(米)" width="60"></el-table-column>
          <el-table-column property="mksafety" label="安全高度(米)" width="60"></el-table-column>
          <el-table-column property="mkbuilding" label="建筑高度(米)" width="60"></el-table-column>
          <el-table-column property="mkdate" label="更新记录时间" width="160"></el-table-column>
          <el-table-column property="mkcontent" label="记录内容" width="200"></el-table-column>
          <el-table-column property="mkremarks" label="备注" width="200"></el-table-column>
          <el-table-column label="环境图片">
            <template v-slot="scope">
              <div style="display: flex; align-items: center">
                <el-image style="width: 40px; height: 40px; border-radius: 10%" v-if="scope.row.mkimg"
                          :src="scope.row.mkimg" :preview-src-list="[scope.row.mkimg]"></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template v-slot="scope">
              <div v-if="scope.row.mktype!=='0'">
                <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>
                <el-button size="mini" type="danger" plain @click="del(scope.row.mkid)">删除</el-button>
              </div>
              <div v-else>
                <el-button size="mini" type="primary" plain @click="handleEdit1(scope.row)">编辑图片</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="outerVisible = false">关 闭</el-button>
        </div>
      </el-dialog>

      <el-dialog title="标记点信息编辑" :visible.sync="editFromVisible" width="40%" :close-on-click-modal="false" destroy-on-close>
        <el-form :model="form" label-width="100px" style="padding-right: 50px" :rules="rules" ref="formRef">
          <el-form-item label="名称">
            <el-input v-model="form.mkname" disabled></el-input>
          </el-form-item>
          <el-form-item label="建筑高度" prop="mkbuilding">
            <el-input v-model="form.mkbuilding" placeholder="建筑高度"></el-input>
          </el-form-item>
          <el-form-item label="安全高度">
            <el-input v-model="form.mksafety" disabled></el-input>
          </el-form-item>
          <el-form-item label="更新记录内容" prop="mkcontent">
            <el-input v-model="form.mkcontent" placeholder="更新记录内容"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="mkremarks">
            <el-input v-model="form.mkremarks" placeholder="备注"></el-input>
          </el-form-item>
          <el-form-item label="现场图片">
            <el-upload
                class="avatar-uploader"
                :action="$baseUrl + '/files/upload'"
                :headers="{ token: user.token }"
                list-type="picture"
                :on-success="handleImgSuccess"
            >
              <el-button type="primary">上传图片</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="fromVisible = false">取 消</el-button>
          <el-button type="primary" @click="save">保 存</el-button>
        </div>
      </el-dialog>
      <el-dialog title="两点间距离" :visible.sync="distanceVisible" width="40%" :close-on-click-modal="false" destroy-on-close>
        <el-input v-model="aPointer"></el-input>
        <el-input v-model="bPointer"></el-input>
        结果：{{distanceResult}}
        <el-button type="primary" @click="distance">计算</el-button>

      </el-dialog>
    </el-col>
  </el-row>
</template>

<script>
//引入组件
import {
  BaiduMap,
  BmControl,
  BmView,
  BmAutoComplete,
  BmLocalSearch,
  BmMarker,
  BmGeolocation,
} from "vue-baidu-map";
import DistanceTool from 'bmaplib.distancetool'
import moment from 'moment'
import QRCode from "qrcode2";

export default {
  name: "Monitoring",
  //需要引入的组件
  components: {
    BaiduMap,
    BmControl,
    BmView,
    BmAutoComplete,
    BmLocalSearch,
    BmMarker,
    BmGeolocation,
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data(){
    var validateValue = (rule, value, callback) => {
      if (/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value) == false) {
        callback(new Error("请输入正整数或两位小数"));
      } else {
        callback();
      }
    };
    var lng,lat,lng1,lat1,lng2,lat2,lng3,lat3,altitude,building
    return{
      showViewer: false,
      num: 0,
      position: ['东北','东','东南','南','西南','西','西北','北','全景',],
      photoDta: [],
      srcList: [],      // 方位图
      showTile: false,  //打开瓦片图
      tableData: [],  // 所有的数据
      pageNum: 1,   // 当前的页码
      pageSize: 5,  // 每页显示的个数
      total: 0,
      username: null,
      fromVisible: false,

      radio: '1',
      outerVisible: false,
      innerVisible: false,
      ruleForm: { // 标记点表单
        mkname: '',
        mkaltitude: '',
        mkbuilding: '',
        mkcontent: '',
        mkremarks: '',
        mkdistance: 0,
        mksafety: 30,
        GPS: '',
      },
      intputForm: {
        intputLng: '',
        intputLng1: '',
        intputLng2: '',
        intputLng3: '',
        intputLat: '',
        intputLat1: '',
        intputLat2: '',
        intputLat3: '',
      },
      rules: {
        mkname: [
          { required: true, message: '请输入标记点名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        mkaltitude: [ {required: true, validator: validateValue, trigger: "blur" }, ],
        mkbuilding: [ {required: true, validator: validateValue, trigger: "blur" }, ],
      },
      rules1: {

      },
      dialogVisible: false,
      button3: '拾取坐标',
      visible: false,
      myMap:{},
      test: false,
      test1: false,
      testPoint: {lng: 0, lat: 0},
      clickFlag: false,
      uploadImg: '',

      editFromVisible: false,
      form: {},
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      map: {
        center: {lng: 108.644167, lat: 24.481389},
        zoom: 16,
      },
      mapStyle: {
        styleJson: [
          {
            "featureType": "all",
            "elementType": "geometry",
            "stylers": {
              "hue": "#007fff",
              "saturation": 89
            }
          },{
            "featureType": "water",
            "elementType": "all",
            "stylers": {
              "color": "#ffffff"
            }
          }
        ]
      },
      circlePath: {
        center: {
          lng: 108.644167,
          lat: 24.481389
        },
        radius: 1000,
        mkaltitude: 0,
        mkunit: '',
        mkname: '',
      },
      markers: [],
      distanceVisible: false,
      aPointer: '',
      bPointer: '',
      distanceResult: 0,

    }
  },
  mounted(){  //读取所有标记信息
    this.load(1)
    // 获取台站方位图 /map-markers/findAllPhoto
    this.request.get('/map-markers/findAllPhoto/' + this.user.department,

    ).then(res => {
      this.photoDta = res.data
      var srcList = []
      console.log("获取台站方位图:",res)
      res.data.forEach((item)=>{
        srcList.push(item.imagePath)
      })
      this.srcList = srcList
    })
   // alert( moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
  },
  unmount () {
    distanceTool && distanceTool.close()
  },

  methods: {
    load(pageNum) {  // 分页查询
      if (pageNum) this.pageNum = pageNum
      this.request.get('/map-markers/page', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          department: this.user.department,
        }
      }).then(res => {
        // res=res.data
        console.log(res)
        this.tableData = res.data.records
        this.total = res.data?.total
        console.log('标记数组：', this.tableData, '标记总数：', this.total)
      })
    },
    handleCurrentChange(pageNum) {
      this.load(pageNum)
    },
    handleEdit(row) {   // 编辑数据
      this.form = JSON.parse(JSON.stringify(row))  // 给form对象赋值  注意要深拷贝数据
      this.editFromVisible = true   // 打开弹窗
    },
    save() {   // 保存按钮触发的逻辑  它会触发新增或者更新
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.request.post('/map-markers', this.form).then(res => {
            if (res.code === '200') {  // 表示成功保存
              this.$message.success('保存成功')
              this.load(1)
              this.editFromVisible = false
            } else {
              this.$message.error(res.msg)  // 弹出错误的信息
            }
          })
        }
      })
    },
    handleImgSuccess(response, file, fileList) {
      // 把头像属性换成上传的图片的链接
      this.form.mkimg = response.data
    },
    del(id) {   // 单个删除
      alert(id)
      this.$confirm('您确定删除吗？', '确认删除', {type: "warning"}).then(response => {
        this.request.delete('/map-markers/' + id).then(res => {
          if (res.code === '200') {   // 表示操作成功
            this.$message.success('操作成功')
            this.load(1)
          } else {
            this.$message.error(res.msg)  // 弹出错误的信息
          }
        })
      }).catch(() => {
      })
    },
    submitForm(formName) {
      let that=this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = false
          // 保存标记点到数据库
          var item = {
            mkid: null,
            mkunit: '河池市宜州区气象局',
            mkname: that.ruleForm.mkname,
            mktype: '1',
            mklat: that.testPoint.lat,
            mklng: that.testPoint.lng,
            mkdistance: that.ruleForm.mkdistance,  //距中心距离
            mkaltitude: that.ruleForm.mkaltitude,
            mksafety: that.ruleForm.mksafety,    //安全高度
            mkbuilding: that.ruleForm.mkbuilding,
            mkdate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            mkcontent: that.ruleForm.mkcontent,
            mkremarks: that.ruleForm.mkremarks,
            reserved1: '',
            reserved2: '',
            reserved3: ''
          }
          that.request.post('/map-markers', item).then(res => {
            if (res.code === '200') {
              that.bconvert(that.testPoint.lng, that.testPoint.lat,(result) => {
                this.markers.push({//   //保存成功，在地图上新增标记点
                  id: 2000,
                  show: false,
                  mkunit: 'value.mkunit',
                  mkname: that.ruleForm.mkname,
                  mktype: '1',
                  gps: result,
                  position: {lng: that.testPoint.lng, lat: that.testPoint.lat},
                  mkaltitude: that.ruleForm.mkaltitude,
                  mkbuilding: that.ruleForm.mkbuilding,
                  mkdistance: that.ruleForm.mkdistance,
                  mksafety: that.ruleForm.mksafety,
                  mkcontent: that.ruleForm.mkcontent,
                  mkremarks: that.ruleForm.mkremarks,
                  mkdate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                  tl: that.ruleForm.mkname.length,
                })
              });
              that.$alert('标记点保存成功.', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  that.$message({
                    type: 'info',
                    message: `标记点保存成功`
                  });
                }
              });
            } else {
              that.$alert('标记点创建失败.', '提示', {
                confirmButtonText: '确定',
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    calcHigh(){
      let that=this
      // alert(that.circlePath.mkaltitude)
      console.log(this.circlePath.mkaltitude)
      var saveHigh=0.1*(parseFloat(this.ruleForm.mkdistance)-17.7)+parseFloat(this.circlePath.mkaltitude)-parseFloat(this.ruleForm.mkaltitude)
      // alert(saveHigh.toString())
      this.ruleForm.mksafety=saveHigh.toFixed(1)
    },
    dialogCancel(){ // 取消，把标记删除
      this.dialogVisible = false
      // var allOverlay = this.myMap.getOverlays();
      // for(var i = 0;i<allOverlay.length;i++) {
      //   //删除指定经度的点
      //   console.log(allOverlay[i].point)
      //   if(allOverlay[i].point!=null){
      //   if (allOverlay[i].point.lng == this.testPoint.lng && allOverlay[i].point.lat == this.testPoint.lat) {
      //     this.myMap.removeOverlay(allOverlay[i]);
      //   }}
      // }
    },
    getClickInfo(e) { // 单击地图！
      let that = this
      if(this.test1){
        this.test1=false
        // const lng = parseFloat(e.point.lng)
        // const lat = parseFloat(e.point.lat)
        // const point = new BMap.Point(lng, lat)
        // const marker = new BMap.Marker(point)
        // this.myMap.addOverlay(marker)   //添加标记
        this.dialogVisible=true
        // 百度转GPS，后转度分秒
        this.request.get('/map-markers/BD09ToWGS84',{
          params:{ x: this.testPoint.lng, y: this.testPoint.lat}
        }).then(res => {
          if (res.code === '200') {
            var gpsX = this.toDegrees((res.data[0]).toString())
            var gpsY = this.toDegrees((res.data[1]).toString())
            this.ruleForm.GPS = gpsX[0] + '° ' + gpsX[1] + '\' ' + gpsX[2] + '"' + ' / ' + gpsY[0] + '° ' + gpsY[1] + '\' ' + gpsY[2] + '"'
          }
        })
        // 计算到中心距离
        this.request.get('/map-markers/distance',{
          params:{ x1: this.circlePath.center.lng, y1: this.circlePath.center.lat,
            x2: this.testPoint.lng,y2: this.testPoint.lat}
        }).then(res => {
          if (res.code === '200') {
            this.ruleForm.mkdistance = parseFloat(res.data).toFixed(1)
          }
        })
      }
      if(this.test){  // 点击拾取到坐标！并弹窗显示
        this.test = false
        this.button3 = '拾取坐标'
        // 百度转GPS，后转度分秒，在弹窗
        this.request.get('/map-markers/BD09ToWGS84',{
          params:{ x: this.testPoint.lng, y: this.testPoint.lat}
        }).then(res => {
          if (res.code === '200') {
            var gpsX = this.toDegrees((res.data[0]).toString())
            var gpsY = this.toDegrees((res.data[1]).toString())
            var str = gpsX[0] + '° ' + gpsX[1] + '\' ' + gpsX[2] + '"' + ' / ' + gpsY[0] + '° ' + gpsY[1] + '\' ' + gpsY[2] + '"'
            this.$alert('GPS坐标度分秒：' + str + '<br/>' +
                'GPS坐标数值：' + res.data[0].toFixed(6) + ' / ' + res.data[1].toFixed(6), '当前拾取坐标', {
              confirmButtonText: '确定',
              dangerouslyUseHTMLString:true,
              callback: action => {}
            });
          }
        })
      }
    },
    getLngLat(){
      this.test =! this.test
      this.button3 = this.test === false ? '拾取坐标' : '关闭拾取'
    },
    addMarker1(){
      this.test = false
      this.button3 = '拾取坐标'
      this.test1 = true
      this.myMap.setDefaultCursor("crosshair");
      this.visible = false
    },
    addMarker2(){//直接输入坐标
    },
    mouseMove(e){
      if(this.test){
        this.myMap.setDefaultCursor("crosshair");
      }else{
        this.myMap.setDefaultCursor("pointer");
      }
      if(this.test1){
        this.myMap.setDefaultCursor("crosshair");
      }else{
        this.myMap.setDefaultCursor("pointer");
      }
      this.testPoint.lng = e.point.lng
      this.testPoint.lat = e.point.lat
    },
    // Map渲染完毕后执行代码, 标记在这里从后台读取并加载渲染
    handlerReady({BMap, map}){  // Map渲染完毕后执行代码，否则出错
      let that=this
      // 实例化一个测量工具
      this.distanceTool = new DistanceTool(map, {lineStroke : 2})
      this.myMap = map  // 记录下地图对象，BMap暂时不管，但好多东西可以在MBap修改
      this.request.get('/map-markers/findAll/' + '河池市宜州区气象局').then(res => {
        if (res.code === '200') {
          this.stations = res.data //数据库标记数据放在stations
          console.log(this.stations )
          this.stations.forEach(function(value, index, array){
            if(value.mktype === '0'){ // 站点为中心，画半径1000米的圆
              that.circlePath={center: {lng: value.mklng, lat: value.mklat}, radius: 1000, mkaltitude: value.mkaltitude,
                                mkname: value.mkname, mkunit: value.mkunit}
            }
            // 百度转GPS，后转度分秒
            that.bconvert(value.mklng, value.mklat,(result) => {
              that.markers.push({
                id: index,
                show: false,
                mkunit: value.mkunit,
                mkname: value.mkname,
                mktype: value.mktype,
                mkaltitude: value.mkaltitude,
                gps: result,
                position: {lng: value.mklng, lat: value.mklat},
                mkdistance: value.mkdistance,
                mksafety: value.mksafety,
                mkbuilding: value.mkbuilding,
                mkcontent: value.mkcontent,
                mkremarks: value.mkremarks,
                mkdate: value.mkdate,
                tl: value.mkname.length,
              })
            });
          });
          this.myMap.centerAndZoom(new BMap.Point(this.circlePath.center.lng, this.circlePath.center.lat), 16) //修改中心点，缩放比例
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    backToCenter(){//返回中心
      this.myMap.centerAndZoom(new BMap.Point(this.circlePath.center.lng, this.circlePath.center.lat), 16) //修改中心点，缩放比例
    },
    closeInfo(){
      this.markers[this.markerNumber].show=false;
      this.clickFlag=false
    },
    markclick(it,index){
      this.$message('你点对了标记点：' + this.markers[index].mkname);
      // this.markerNumber=index;
      // this.markers[index].show=true;
      // this.clickFlag=true
      // this.list=this.lists[this.markerNumber]
    },
    dialogOpen(){// 标记点编辑窗弹完后，清空表单
      this.$refs['ruleForm'].resetFields()
    },
    markover(it,index){
      if(!this.clickFlag) {
        this.currentMarker = index
        this.markers[index].show = true;
      }
    },
    markout(it,index){
      if(!this.clickFlag) this.markers[index].show = false;
    },
    infoWindowClose () {
      this.clickFlag = false
    },
    //地图加载后的回调
    mapReady({ BMap, map }) {
      //保存this指向，因为在百度的回调中this不指向vue
      const _this = this;
      // 获取自动定位方法
      var geolocation = new BMap.Geolocation();
      // 获取自动定位获取的坐标信息
      geolocation.getCurrentPosition(
          function (r) {
            //可以conso.log看一下这个r，他里面包含了检索到的位置信息。下面就把两个维度信息赋值给center来定位
            _this.center = {
              lng: r.point.lng,
              lat: r.point.lat,
            };
          },
          //启用高精度
          { enableHighAccuracy: true }
      );
    },
    setDistanceToolInstance ({map}) {
      this.distanceTool = new DistanceTool(map, {lineStroke : 2})
    },
    openDistanceTool (e) {
      const {distanceTool} = this
      distanceTool && distanceTool.open()
    },
    changeRadio(val) {
      if(val === '1'){  // 点击了数值模式，把度分秒转换过来
        if(this.intputForm.intputLng1 && this.intputForm.intputLng2 && this.intputForm.intputLng3 &&
            this.intputForm.intputLat1 && this.intputForm.intputLat2 && this.intputForm.intputLat3){
          var x=parseFloat(this.intputForm.intputLng1)+parseFloat(this.intputForm.intputLng2)/60+parseFloat(this.intputForm.intputLng3)/3600
          var y=parseFloat(this.intputForm.intputLat1)+parseFloat(this.intputForm.intputLat2)/60+parseFloat(this.intputForm.intputLat3)/3600
          this.intputForm.intputLng=x
          this.intputForm.intputLat=y
        }
      }else{  // 点击了度分秒模式，把数值转换成度分秒
        var gpsX=this.toDegrees(this.intputForm.intputLng)
        var gpsY=this.toDegrees(this.intputForm.intputLat)
        this.intputForm.intputLng1=gpsX[0]
        this.intputForm.intputLng2=gpsX[1]
        this.intputForm.intputLng3=gpsX[2]
        this.intputForm.intputLat1=gpsY[0]
        this.intputForm.intputLat2=gpsY[1]
        this.intputForm.intputLat3=gpsY[2]
      }
    },
    handlerIntputGPS(){ // 直接输入GPS坐标对话框的确定按钮
      let that=this
      if(that.radio=='1'){
        if(parseFloat(that.intputForm.intputLng) > 100  &&  parseFloat(that.intputForm.intputLng) < 119 &&
              parseFloat(that.intputForm.intputLat) > 23 && parseFloat(that.intputForm.intputLat) < 25){
          that.dialogVisible = true
          that.innerVisible = false
          var gpsX=that.toDegrees(that.intputForm.intputLng+'')
          var gpsY=that.toDegrees(that.intputForm.intputLat+'')
          that.ruleForm.GPS=gpsX[0]+'° '+gpsX[1]+'\' '+gpsX[2]+'"'+' / '+gpsY[0]+'° '+gpsY[1]+'\' '+gpsY[2]+'"'
          // 计算到中心距离
          that.request.get('/map-markers/WGS84ToBD09',{
            params:{ x: parseFloat(that.intputForm.intputLng),y: parseFloat(that.intputForm.intputLat)}
          }).then(res => {
            if (res.code === '200') {
              that.testPoint.lng=res.data[0]
              that.testPoint.lat=res.data[1]
              that.request.get('/map-markers/distance',{
                params:{ x1: that.circlePath.center.lng, y1: that.circlePath.center.lat,
                  x2: that.testPoint.lng,y2: that.testPoint.lat}
              }).then(res => {
                if (res.code === '200') {
                  that.ruleForm.mkdistance=parseFloat(res.data).toFixed(1)
                }
              })
            }
          })
        } else{
          that.$message('请确认输入范围，经度：100--119，纬度：23--25');
        }
      }else {
        if(that.intputForm.intputLng1 && that.intputForm.intputLng2 && that.intputForm.intputLng3 &&
            that.intputForm.intputLat1 && that.intputForm.intputLat2 && that.intputForm.intputLat3) {
          var x = parseFloat(that.intputForm.intputLng1) + parseFloat(that.intputForm.intputLng2) / 60 + parseFloat(that.intputForm.intputLng3) / 3600
          var y = parseFloat(that.intputForm.intputLat1) + parseFloat(that.intputForm.intputLat2) / 60 + parseFloat(that.intputForm.intputLat3) / 3600
          that.intputForm.intputLng = x
          that.intputForm.intputLat = y
          that.dialogVisible = true
          that.innerVisible = false
          var gpsX=that.toDegrees(that.intputForm.intputLng+'' )
          var gpsY=that.toDegrees(that.intputForm.intputLat+'')
          that.ruleForm.GPS=gpsX[0]+'° '+gpsX[1]+'\' '+gpsX[2]+'"'+' / '+gpsY[0]+'° '+gpsY[1]+'\' '+gpsY[2]+'"'
          // 计算到中心距离
          that.request.get('/map-markers/WGS84ToBD09',{
            params:{ x: parseFloat(that.intputForm.intputLng),y: parseFloat(that.intputForm.intputLat)}
          }).then(res => {
            if (res.code === '200') {
              that.testPoint.lng=res.data[0]
              that.testPoint.lat=res.data[1]
              that.request.get('/map-markers/distance',{
                params:{ x1: that.circlePath.center.lng, y1: that.circlePath.center.lat,
                  x2: that.testPoint.lng,y2: that.testPoint.lat}
              }).then(res => {
                if (res.code === '200') {
                  that.ruleForm.mkdistance=parseFloat(res.data).toFixed(1)
                }
              })
            }
          })
          }else{
            that.$message('请确认输入是否正确');
        }
      }
    },
    // 在 Input 值改变时触发
    handleAltitude(e){
      this.ruleForm.mkaltitude = this.altitude = this.validLngLat(e, 0, 999, 1, this.altitude)
    },
    handleBuilding(e){
      this.ruleForm.mkbuilding = this.building = this.validLngLat(e, 0, 300, 1, this.building)
    },
    handleLng(e) {
      this.intputForm.intputLng = this.lng = this.validLngLat(e, 0, 120, 8, this.lng)
    },
    handleLat(e) {
      this.intputForm.intputLat = this.lat = this.validLngLat(e, 0, 26, 8, this.lat)
    },
    handleLng1(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      value = value.replace(/(\d{3})\d*/, '$1')     //100--120，最多保留3位整数
      value = value.replace(/([1][2-9][0-9])\d*/, value.substring(0, 2)) // 120--199，3位取头2位
      value = value.replace(/([2-9][0-9][0-9])\d*/, value.substring(0, 2)) // 200--999，3位取头2位
      this.intputForm.intputLng1 = value
    },
    handleLng2(e) {
      this.intputForm.intputLng2 = this.validLngLat2(e)
    },
    handleLng3(e) {
      this.intputForm.intputLng3 =  this.lng3 = this.validLngLat(e, 0, 60, 2, this.lng3)
    },
    handleLat1(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      value = value.replace(/(\d{2})\d*/, '$1')     //100--120
      value = value.replace(/([2][6-9])\d*/, value.substring(0, 1))  // 26--29
      value = value.replace(/([3-9][0-9])\d*/, value.substring(0, 1)) //30-99
      this.intputForm.intputLat1 = value
    },
    handleLat2(e) {
      this.intputForm.intputLat2 = this.validLngLat2(e)
    },
    handleLat3(e) {
      this.intputForm.intputLat3 = this.lat3 = this.validLngLat(e, 0, 60, 2, this.lat3)
    },
    validLngLat(e, min, max, digit, oldValue){  // 取小数范围0.xxxxx-xxx.xxxxx，参数：e, min, max, digit, oldValue
      let value = e.replace(/[^\d.]/g, ""); // 只能输入数字和小数点
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      if((value.split('.')).length > 2){  // 输入第二小数点，取原数
        value = oldValue
      }
      if((value.split('.')).length === 2){  //  是小数了，保留8位数
        value = (value.split('.'))[1].length > digit ?  oldValue : value
      }
      if(value > max || value == max){  // 这里value == 120，强制value字符与数值比较
        value =  oldValue
      }
      return value
    },
    validLngLat2(e) { // 取0--59的整数
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      value = value.replace(/(\d{2})\d*/, '$1')
      value = value.replace(/([6-9][0-9])\d*/, value.substring(0, 1)) // 60--99，2位取头1位
      return  value
    },
    bconvert(x, y, callback){
      let that = this
      that.request.get('/map-markers/BD09ToWGS84',{
        params:{ x: x, y: y}
      }).then(res => {
        if (res.code === '200') {
          var gpsX=that.toDegrees((res.data[0]).toString())
          var gpsY=that.toDegrees((res.data[1]).toString())
          callback(gpsX[0] + '°' + gpsX[1] + '\'' + gpsX[2] + '"' + '/' + gpsY[0] + '°' + gpsY[1] + '\'' + gpsY[2] + '"')
        }
      })
    },
    // 经纬度转度分秒
    // d = integer(30.263888889°) = 30°
    // m = integer((dd - d) × 60) = 15'
    // s = (dd - d - m/60) × 3600 = 50"
    toDegrees(val){
      if(typeof val == "undefined" || val == ''){
        return '';
      }
      let i = val.indexOf('.');
      let strDu = i<0?val:val.substring(0,i); // 度// 度取.的前字符, 如无.则取val
      let strFen = 0;       // 分
      let strMiao = 0;      // 秒
      if(i > 0){ //有小数点时
        strFen = parseInt((val - strDu)*60).toString()
        i = ((val - strDu)*60).toString().indexOf('.');          //
        if(i > 0){
          strMiao = (val - strDu - strFen/60)*3600
          strMiao = strMiao.toFixed(1)
        }
      }
      return [strDu, strFen, strMiao];
    },

    openSrcList(){
      // console.log(value)
      this.srcList = [value]
      this.$refs.imgViewer.viewBigPicture()
      // var e = document.createEvent('MouseEvents')
      // e.initEvent('click', true, true)
      // document.getElementById('openSrcList').dispatchEvent(e)
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    onSwitch(e){
      console.log(e)
      this.num = e
    },
    phototDownload(){
      window.open(this.photoDta[this.num].imagePath, '_blank');
    },
    distance(){ // 两点间距离
      let that = this
      var arr1 = this.aPointer.split(",")
      var arr2 = this.bPointer.split(",")
      var x1 = arr1[0], y1 = arr1[1], x2 = arr2[0], y2 = arr2[1]
      that.request.get('/map-markers/distance',{
        params:{ x1: x1, y1: y1,
          x2: x2, y2: y2}
      }).then(res => {
        if (res.code === '200') {
          this.distanceResult = res.data
        }
      })

    },
  },



}
</script>

<style scoped>
  .baidumap ::v-deep .BMap_stdMouse {
    /* 自定义鼠标样式 */
    cursor: crosshair;
  }

  .el-dialog__header{
    padding: 0!important;
  }
  ::v-deep .el-dialog {
    padding: 0!important;
    background: whitesmoke !important;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px!important;
    /*background: #214282 !important;*/
  }

  /*隐藏下箭头、上、中、下*/
  ::v-deep .BMap_pop img{
    display: none;
  }
  ::v-deep .BMap_top{
    display: none;
  }
  ::v-deep .BMap_center{
    display: none;
  }
  ::v-deep .BMap_bottom{
    display: none;
  }
   /*隐藏四个角的div块*/
   ::v-deep .BMap_pop > div:nth-child(1) {
     display: none !important;
   }
  ::v-deep .BMap_pop > div:nth-child(3) {
    display: none !important;
  }
  ::v-deep .BMap_pop > div:nth-child(5) {
    display: none !important;
  }
  ::v-deep .BMap_pop > div:nth-child(7) {
    display: none !important;
  }
  /*现在就剩下这个了！修改样式！*/
  /deep/.BMap_bubble_content {
    /*border-top: 3px solid #377abd;*/
    /*border-bottom: 3px solid #377abd;*/
    border-radius: 8px;
    background-color: rgba(36, 105, 137, 0.8);
    overflow: hidden;
    color: #ffffff;
    /*padding: 8px 5px;*/
    font-size: 14px;
    width: 240px !important;
  }
.fileName{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 180px;
  border-radius: 6px;
  position:fixed;
  bottom: 10vh;
  left: calc(50vw - 100px);
  background-color: #606266;
  opacity: .8;
  cursor: pointer;
  z-index: 4019;
  color: #e56c3c;
  font-size: 24px;
  font-weight: 700;
}

</style>